<template>
    <v-btn class="whatsapp-button" @click="openWhatsApp" color="#25D366" elevation="4" fab large bottom right fixed>
        <v-icon color="white"> mdi-whatsapp </v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'WhatsAppButton',
    methods: {
        openWhatsApp() {
            window.open('https://wa.me/5551985246506')
        }
    }
}
</script>

<style>
</style>