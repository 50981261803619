<template>
    <div class="home-page">
        <ImageText nameId="inicio-contabil" maxHeight="400px" title="INTEGRADOR CONTÁBIL" subtitle="" description="O Integrador Contábil é um sistema desenvolvido para escritórios de contabilidade que trabalha juntamente com o sistema contábil interno da empresa. Através dele são automatizadas diversas tarefas internas que facilitam e otimizam o tempo dos contadores que utilizam o sistema contábil. Dessas tarefas, o Integrador Contábil consegue realizar cadastros automáticos a partir de planilhas digitais, fazer conferência na numeração das notas fiscais, fazer conversões dos produtos das notas fiscais recebidas pelo cliente, além de uma série de outras vantagens." />
        <RecursosAplicacao nameId="recursos-contabil" :recursos="recursos" heightImg="300px" widthCard="450px" />
        <ContatoCodezz nameId="contato-contabil" title="Entre em contato conosco" subtitle="Solicite um orçamento, peça uma demonstração ou esclareça suas dúvidas. Estamos aqui para ajudar você!" tipoSistema="integradorContabil" />
        <ClientesCodezz title="Empresas que utilizam o sistema" tipoCliente="integradorContabil" />
    </div>
</template>

<script>
import ImageText from '@/components/templates/ImageText'
import RecursosAplicacao from '@/components/templates/RecursosAplicacao'
import ContatoCodezz from '@/components/templates/ContatoCodezz'
import ClientesCodezz from '@/components/templates/ClientesCodezz'

export default {
    name: 'ContabilPage',
    components: {
        ImageText,
        RecursosAplicacao,
        ContatoCodezz,
        ClientesCodezz
    },
    data() {
        return {
            recursos: [
                {
                    title: 'LANÇAMENTOS DE CRÉDITO',
                    description: 'Configure as planilhas de crédito que serão importadas e o Integrador Contábil gera um arquivo pra ser importado no seu sistema contábil. Com isso você não precisa cadastrar manualmente nenhum lançamento de crédito',
                    image: require('@/assets/contabil/lancamentos-credito.png'),
                    clickable: true 
                },
                {
                    title: 'LANÇAMENTOS DE BAIXAS DE TÍTULO',
                    description: 'Configure as planilhas de baixas que serão importadas e o Integrador Contábil gera um arquivo pra ser importado no seu sistema contábil. Com isso você não precisa cadastrar manualmente nenhum lançamento de baixa de título',
                    image: require('@/assets/contabil/lancamentos-baixas.png'),
                    clickable: true 
                },
                {
                    title: 'CONVERSÃO DO PRODUTO DAS NF-E',
                    description: 'Altera o código do produto no XML do fornecedor de seu cliente pra ser importado no seu sistema contábil. A conversão é feita apenas informando um arquivo SPED Fiscal ou realizando o cadastro das conversões',
                    image: require('@/assets/contabil/conversao-produtos.png'),
                    clickable: true 
                },
                {
                    title: 'CONFERÊNCIA DE NF-E POR SÉRIE',
                    description: 'Identifica quais os números das NF-e que faltam dentre todas as notas emitidas pelo cliente em um período específico de tempo. Dessa forma o contador identifica essas NF-e de forma rápida',
                    image: require('@/assets/contabil/series.png'),
                    clickable: true 
                },
                {
                    title: 'COMPARATIVO DE CRÉDITO E FATURAMENTO',
                    description: 'Informe os valores de crédito por bandeira e o faturamento mensal da empresa, assim o Integrador Contábil compara o crédito com o faturamento e também mostra a tarifa de crédito mensal para cada bandeira',
                    image: require('@/assets/contabil/comparativo-cred-fat.png'),
                    clickable: true 
                },
                {
                    title: 'GERAÇÃO DO DANFE A PARTIR DOS XMLS',
                    description: 'Gera uma pasta com todos os DANFE em PDF, para todos os XMLs que foram importados a partir de uma pasta, além de imprimir individualmente cada NF-e dos XMLs importados',
                    image: require('@/assets/contabil/danfe.png'),
                    clickable: true 
                }
            ],
            ofertasContabil: [
                {
                    titulo: 'Mensal',
                    subtitulo: 'Plano válido por apenas 1 mês',
                    preco: 59.99,
                    multiplo: 1,
                    valor: 0,
                    show: false
                },
                {
                    titulo: 'Trimestral',
                    subtitulo: 'Plano válido por 3 meses',
                    preco: 55.99,
                    multiplo: 3,
                    valor: 0,
                    show: false
                },
                {
                    titulo: 'Semestral',
                    subtitulo: 'Plano válido por 6 meses',
                    preco: 49.99,
                    multiplo: 6,
                    valor: 0,
                    show: false
                },
                {
                    titulo: 'Anual',
                    subtitulo: 'Plano válido por 1 ano',
                    preco: 45.99,
                    multiplo: 12,
                    valor: 0,
                    show: false
                }
            ]
        }
    }
}
</script>

<style>

</style>