<template>
    <div class="thanks-page flex text-center">
        <br>
        <h1 class="texto">
            Obrigado pelo seu contato
        </h1>
        <br>
        <h3 class="texto">
            Retornaremos o mais breve possível
        </h3>
        <br>
        <v-btn :color="global.corAzul" outlined @click="voltarInicio">
            Voltar para o início
        </v-btn>
    </div>
</template>

<script>
import global from '@/config/global'

export default {
    name: 'ThanksPage',
    data() {
        return {
            global
        }
    },
    methods: {
        voltarInicio() {
            this.$router.push({
                name: 'homePage'
            })
        }
    }
}
</script>

<style>
    .texto {
        font-weight: 500;
    }
</style>