<template>
    <v-app>
        <HeaderSite />
        <router-view></router-view>
        <FooterSite />
        <WhatsAppButton />
    </v-app>
</template>

<script>
import HeaderSite from '@/components/templates/HeaderSite.vue'
import FooterSite from '@/components/templates/FooterSite.vue'
import WhatsAppButton from '@/components/templates/WhatsAppButton.vue'

export default {
    name: 'App',
    components: {
        HeaderSite,
        FooterSite,
        WhatsAppButton
    }
}
</script>

<style>
    * {
        font-family: 'Outfit', sans-serif;
    }
</style>