<template>
    <div v-if="clientesMostrar.length > 0">
        <v-container>
            <h2 class="text-center ma-5">{{ title }}</h2>
            <br>

            <v-row>
                <v-col v-for="(cliente, i) in clientesMostrar" :key="i" align-self="center" class="d-flex justify-center">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-card v-bind="attrs" v-on="on" class="mx-auto" width="100px" @click="openSite(cliente.site)" outlined color="transparent">
                                <v-img :src="cliente.imagem" height="100px" loading="lazy"></v-img>
                            </v-card>
                        </template>
                        <span>{{ cliente.nome }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-container>

        <v-divider class="mt-5"></v-divider>
    </div>
</template>

<script>
import { clientes } from '@/config/clientes'

export default {
    name: 'ClientesCodezz',
    props: ['title', 'tipoCliente'],
    data() {
        return {
            clientesMostrar: []
        }
    },
    methods: {
        openSite(urlSite) {
            if (urlSite != '') {
                window.open(urlSite, '_blank').focus()
            }
        }
    },
    mounted() {
        clientes.forEach(el => {
            if (el.tipo.includes(this.tipoCliente)) {
                this.clientesMostrar.push(el)
            }
        });
    }
}
</script>

<style>

</style>