<template>
    <div class="footer-site" :style="fundoAzul">
        <v-container class="mt-6">
            <v-col v-for="(contato, i) in contatos" :key="i" class="pa-0 ma-0 mb-3">
                <p class="text-center" :style="fonteBranca">
                    <v-icon :color="corVerde">{{ contato.icone }}</v-icon>
                    {{ contato.contato }}
                </p>
            </v-col>

            <v-row justify="center" class="mt-10 mb-1" :style="fonteBranca">
                <span>Codezz Soluções em Tecnologia LTDA - {{ getAnoAtual() }}</span>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { fonteBranca, fundoAzul, corVerde } from '@/config/global'

export default {
    name: 'FooterSite',
    data() {
        return {
            fonteBranca,
            fundoAzul,
            corVerde,
            contatos: [
                {
                    contato: 'codezz@codezz.com.br',
                    icone: 'mdi-email-outline'
                },
                {
                    contato: '@codezzsistemas',
                    icone: 'mdi-instagram'
                }
            ]
        }
    },
    methods: {
        getAnoAtual() {
            const d = new Date()
            return d.getUTCFullYear()
        }
    }
}
</script>

<style>

</style>