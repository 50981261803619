<template>
    <div class="home-page">
        <ImageText nameId="inicio-agendamento" maxHeight="420px" title="AGENDAMENTOS CODEZZ" subtitle="Não perca tempo organizando manualmente a sua agenda" description="Com os Agendamentos da Codezz você centraliza os agendamentos de seus clientes em um único lugar, substituindo a necessidade de uma agenda. Cadastre seus serviços, preços, horários de trabalho, gere agendas por período e por cada funcionário de seu estabelecimento, deixando que seus próprios clientes marquem e/ou cancelem os agendamentos através da plataforma." />
        <RecursosAplicacao nameId="recursos-agendamentos" :recursos="recursos" heightImg="225px" widthCard="430px" />
        <ContatoCodezz nameId="contato-agendamentos" title="Entre em contato conosco" subtitle="Solicite um orçamento, peça uma demonstração ou esclareça suas dúvidas. Estamos aqui para ajudar você!" tipoSistema="agendamentosCodezz" />
        
        <!-- Acesse ou teste a plataforma -->
        <v-container>
            <h2 class="text-center ma-5">Acesse ou teste a plataforma gratuitamente</h2>
            <p class="text-center" style="font-weight: 300">Você pode acessar a tela de login dos Agendamentos da Codezz ou registrar-se gratuitamente clicando nos botões abaixo</p>
            <br>

            <v-layout justify-center>
                <v-btn @click="abrirLoginAgendamentos" class="mr-10" color="white" dark outlined>Acesse a plataforma</v-btn>
                <v-btn @click="abrirCadastroAgendamentos" color="white" dark outlined>Registre-se grátis</v-btn>
            </v-layout>
        </v-container>
        <v-divider class="mt-5"></v-divider>

        <ClientesCodezz title="Empresas que utilizam a plataforma" tipoCliente="agendamentosCodezz" />
    </div>
</template>

<script>
import ImageText from '@/components/templates/ImageText'
import RecursosAplicacao from '@/components/templates/RecursosAplicacao'
import ContatoCodezz from '@/components/templates/ContatoCodezz'
import ClientesCodezz from '@/components/templates/ClientesCodezz'
import { corAzul, urlAgendamentos } from '@/config/global'

export default {
    name: 'AgendamentosPage',
    components: {
        ImageText,
        RecursosAplicacao,
        ContatoCodezz,
        ClientesCodezz
    },
    data() {
        return {
            corAzul,
            recursos: [
                {
                    title: 'CRIE SERVIÇOS',
                    description: 'Crie serviços que você oferecerá aos seus clientes, informando seu respectivo valor e tempo de execução.',
                    image: require('@/assets/agendamentos/servicos-mob.png'),
                    clickable: true 
                },
                {
                    title: 'CONFIGURE SEU HORÁRIO DE TRABALHO',
                    description: 'Informe quais dias da semana você trabalha e quais horários ficarão disponíveis para seus clientes.',
                    image: require('@/assets/agendamentos/horarios-mob.png'),
                    clickable: true 
                },
                {
                    title: 'CADASTRE MAIS PROFISSIONAIS',
                    description: 'Cadastre todos os profissionais que trabalham na empresa, cada um deles terá sua própria agenda.',
                    image: require('@/assets/agendamentos/profissionais-mob.png'),
                    clickable: true 
                },
                {
                    title: 'GERE SUA AGENDA',
                    description: 'Gere sua agenda de forma periódica para seus clientes marcarem os agendamentos.',
                    image: require('@/assets/agendamentos/agenda-mob.png'),
                    clickable: true 
                },
                {
                    title: 'MARQUE OS AGENDAMENTOS',
                    description: 'Seu cliente poderá agendar facilmente um horário, selecionando o profissional e o serviço disponível.',
                    image: require('@/assets/agendamentos/agendamento-mob.png'),
                    clickable: true 
                },
                {
                    title: 'GERENCIE SUAS INFORMAÇÕES',
                    description: 'Visualize relatórios dos seus clientes, agendamentos realizados/cancelados, controle financeiro, entre outros.',
                    image: require('@/assets/agendamentos/relatorios-mob.png'),
                    clickable: true 
                }
            ],
            ofertasAgendamentos: [
                {
                    titulo: 'Mensal',
                    subtitulo: 'Plano válido por apenas 1 mês',
                    preco: 55.99,
                    multiplo: 1,
                    valor: 0,
                    show: false
                },
                {
                    titulo: 'Trimestral',
                    subtitulo: 'Plano válido por 3 meses',
                    preco: 50.99,
                    multiplo: 3,
                    valor: 0,
                    show: false
                },
                {
                    titulo: 'Semestral',
                    subtitulo: 'Plano válido por 6 meses',
                    preco: 45.99,
                    multiplo: 6,
                    valor: 0,
                    show: false
                },
                {
                    titulo: 'Anual',
                    subtitulo: 'Plano válido por 1 ano',
                    preco: 40.99,
                    multiplo: 12,
                    valor: 0,
                    show: false
                }
            ]
        }
    },
    methods: {
        abrirLoginAgendamentos() {
            window.open(`${urlAgendamentos}/login_profissional`, '_blank').focus()
        },
        abrirCadastroAgendamentos() {
            window.open(`${urlAgendamentos}/nova_empresa`, '_blank').focus()
        }
    }
}
</script>

<style>

</style>