<template>
    <div class="home-page">
        <ImageText nameId="inicio-atendimento" maxHeight="420px" title="GERENCIADOR DE ATENDIMENTOS" subtitle="" description="O Gerenciador de Atendimentos tem a função de organizar e distribuir todos os chamados dos clientes entre os funcionários que utilizam a plataforma, tendo assim um controle do que cada funcionário precisa fazer, mantendo também o histórico de tudo o que já foi feito. Além disso, a plataforma é 100% customizável para atender as necessidades da sua empresa, você cria seus próprios setores, canais e status dos atendimento, ativa e desativa usuários conforme a necessidade, entre outras funcionalidades. Veja abaixo todos os recursos da plataforma e solicite-nos uma demonstração." />
        <RecursosAplicacao nameId="recursos-atendimento" :recursos="recursos" heightImg="225px" widthCard="430px" />
        <ContatoCodezz nameId="contato-atendimento" title="Entre em contato conosco" subtitle="Solicite um orçamento, peça uma demonstração ou esclareça suas dúvidas. Estamos aqui para ajudar você!" tipoSistema="gerenciadorAtendimentos" />
        
        <!-- Acesse ou teste a plataforma -->
        <v-container>
            <h2 class="text-center ma-5">Acesse ou teste a plataforma gratuitamente</h2>
            <p class="text-center" style="font-weight: 300">Você pode acessar a tela de login do Gerenciador de Atendimentos ou registrar-se gratuitamente clicando nos botões abaixo</p>
            <br>

            <v-layout justify-center>
                <v-btn @click="abrirLoginAtendimentos" class="mr-10" color="white" dark outlined>Acesse a plataforma</v-btn>
                <v-btn @click="abrirCadastroAtendimentos" color="white" dark outlined>Registre-se grátis</v-btn>
            </v-layout>
        </v-container>
        <v-divider class="mt-5"></v-divider>

        <ClientesCodezz title="Empresas que utilizam a plataforma" tipoCliente="gerenciadorAtendimentos" />
    </div>
</template>

<script>
import ImageText from '@/components/templates/ImageText'
import RecursosAplicacao from '@/components/templates/RecursosAplicacao'
import ContatoCodezz from '@/components/templates/ContatoCodezz'
import ClientesCodezz from '@/components/templates/ClientesCodezz'
import { corAzul, urlAtendimentos } from '@/config/global'

export default {
    name: 'AtendimentosPage',
    components: {
        ImageText,
        RecursosAplicacao,
        ContatoCodezz,
        ClientesCodezz
    },
    data() {
        return {
            corAzul,
            recursos: [
                {
                    title: 'CRIE ATENDIMENTOS',
                    description: 'Crie os chamados recebidos dos seus clientes e destine aos funcionários responsáveis',
                    image: require('@/assets/atendimentos/criar.png'),
                    clickable: true 
                },
                {
                    title: 'ACOMPANHE SEUS ATENDIMENTOS',
                    description: 'Saiba todos os atendimentos que estão em aberto para você e tudo o que já foi concluído',
                    image: require('@/assets/atendimentos/atendimentos.png'),
                    clickable: true 
                },
                {
                    title: 'ATUALIZE OS ATENDIMENTOS',
                    description: 'Crie históricos para atualizar seus atendimentos, assim você sabe o que já foi feito e o que ainda falta fazer',
                    image: require('@/assets/atendimentos/atualiza.png'),
                    clickable: true 
                },
                {
                    title: 'DEIXE O CLIENTE CRIAR OS ATENDIMENTOS',
                    description: 'Configure um código para o funcionário e deixe o cliente criar os chamados diretamente pela plataforma',
                    image: require('@/assets/atendimentos/criar-cliente.png'),
                    clickable: true 
                },
                {
                    title: 'MONITORE OS ATENDIMENTOS',
                    description: 'Visualize todos os atendimentos em aberto em uma fila que é atualizada em tempo real',
                    image: require('@/assets/atendimentos/monitorar.png'),
                    clickable: true 
                },
                {
                    title: 'ACOMPANHE TODOS OS ATENDIMENTOS',
                    description: 'Usuários com permissão de administrador conseguem visualizar os atendimentos de toda a empresa',
                    image: require('@/assets/atendimentos/atendimentos-admin.png'),
                    clickable: true 
                },
                {
                    title: 'CONFIGURE CONFORME SUA NECESSIDADE',
                    description: 'Configure setores, usuários, status, canais conforme a sua necessidade específica',
                    image: require('@/assets/atendimentos/config.png'),
                    clickable: true 
                }
            ],
            ofertasAtendimentos: [
                {
                    titulo: 'Mensal',
                    subtitulo: 'Plano válido por apenas 1 mês',
                    preco: 24.99,
                    multiplo: 1,
                    valor: 0,
                    show: false
                },
                {
                    titulo: 'Trimestral',
                    subtitulo: 'Plano válido por 3 meses',
                    preco: 19.99,
                    multiplo: 3,
                    valor: 0,
                    show: false
                },
                {
                    titulo: 'Semestral',
                    subtitulo: 'Plano válido por 6 meses',
                    preco: 17.99,
                    multiplo: 6,
                    valor: 0,
                    show: false
                },
                {
                    titulo: 'Anual',
                    subtitulo: 'Plano válido por 1 ano',
                    preco: 15.99,
                    multiplo: 12,
                    valor: 0,
                    show: false
                }
            ]
        }
    },
    methods: {
        abrirLoginAtendimentos() {
            window.open(`${urlAtendimentos}/login`, '_blank').focus() //Abre a plataforma em outra aba do navegador (_blank)
        },
        abrirCadastroAtendimentos() {
            window.open(`${urlAtendimentos}/nova_empresa`, '_blank').focus() //Abre a plataforma em outra aba do navegador (_blank)
        }
    }
}
</script>

<style>

</style>