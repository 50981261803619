<template>
    <div :id="nameId">
        <v-container fill-height class="flex-column">
            <h2 class="text-center ma-5">{{ title }}</h2>
            <p class="text-center" style="font-weight: 300; padding-bottom: 10px;">{{ subtitle }}</p>
            <br>

            <v-row align="center" justify="center">
                <v-card elevation="4" class="text-center mx-2" max-width="650px">
                    <v-card-text>
                        <v-form style="font-weight: 300">
                            <v-text-field v-model="contato.con_nome" label="Nome" :color="corAzul" dense></v-text-field>
                            <v-text-field v-model="contato.con_cargo" label="Cargo" :color="corAzul" dense></v-text-field>
                            <v-text-field v-model="contato.con_empresa" label="Empresa" :color="corAzul" dense></v-text-field>
                            <v-text-field v-model="contato.con_email" label="E-mail" :color="corAzul" dense></v-text-field>
                            <v-text-field v-model="contato.con_telefone" label="Telefone" :color="corAzul" dense v-mask="'(##) #####-####'"></v-text-field>
                            <v-textarea v-model="contato.con_descricao" label="Descrição/Necessidade" :color="corAzul" dense></v-textarea>
                        </v-form>
                    </v-card-text>
                    <v-card-text>
                        <p class="text-left" style="font-weight: 300">Ao preencher o formulário acima, você está de acordo com a nossa <a href class="privacidade" @click.prevent="openPrivacidade"><strong>Política de Privacidade</strong></a></p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="ml-2 mb-2" @click="enviarContato" :color="corAzul" dark small>Enviar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-row>
            <br>
        </v-container>

        <v-divider class="mt-5"></v-divider>
    </div>
</template>

<script>
import { corAzul, urlSiteCodezz, urlBackendCodezz, showError } from '@/config/global'
import axios from 'axios'

export default {
    name: 'ContatoCodezz',
    props: ['nameId', 'title', 'subtitle', 'tipoSistema'],
    data() {
        return {
            corAzul,
            contato: {}
        }
    },
    methods: {
        enviarContato() {
            this.contato.con_ok = false
            this.contato.con_sistema = this.tipoSistema
            this.contato.con_telefone = this.getFoneSemMascara(this.contato.con_telefone)

            axios.post(`${urlBackendCodezz}/contatos`, this.contato)
                .then(() => {
                    this.$toasted.global.msgSuccess({
                        msg: 'Pedido de contato registrado com sucesso!'
                    })
                    
                    this.contato = {}

                    this.$router.push({
                        name: 'obrigado'
                    })
                })
                .catch(showError)
        },
        openPrivacidade() {
            window.open(`${urlSiteCodezz}/politica-privacidade`, '_blank').focus()
        },
        getFoneSemMascara(telefone){
            if(telefone){
                return telefone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
            } else {
                return ''
            }
        }
    }
}
</script>

<style>
    .privacidade {
        text-decoration: none;
    }

    .privacidade strong {
        color: black;
    }
</style>