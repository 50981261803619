<template>
    <div :id="nameId">
        <v-container flex-column>
            <h2 class="text-center ma-5">Recursos</h2>
            <br>

            <v-row>
                <v-col v-for="(recurso, i) in recursos" :key="i" align-self="center" class="d-flex justify-center">
                    <v-hover v-slot="{ hover }">
                        <v-card :elevation="hover ? 14 : 4" class="mx-auto" :width="widthCard">
                            <v-img loading="lazy" class="imagem-recurso" :src="recurso.image" :height="heightImg" contain @click="recurso.clickable ? abrirImagem(i) : null"></v-img>

                            <h3 class="pa-4">{{ recurso.title }}</h3>
                            <p class="px-4" style="font-weight: 300">{{ recurso.description }}</p>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
            <br>

            <v-dialog v-model="dialog">
                <v-img v-if="indexImg > -1" :src="recursos[indexImg].image" max-height="750px" contain background-color="transparent" loading="lazy" />
            </v-dialog>
        </v-container>

        <v-divider class="mt-5"></v-divider>
    </div>
</template>

<script>
export default {
    name: 'RecursosAplicacao',
    props: ['nameId', 'recursos', 'heightImg', 'widthCard'],
    data() {
        return {
            dialog: false,
            indexImg: -1
        }
    },
    methods: {
        abrirImagem(index) {
            this.indexImg = index
            this.dialog = true
        }
    }
}
</script>

<style>
    .imagem-recurso {
        cursor: pointer;
    }
</style>