<template>
    <div class="home-page">
        <ImageText nameId="inicio-erp" maxHeight="420px" title="ERP CODEZZ" subtitle="Gerencie todas as operações da sua empresa de forma integrada e eficiente" description="Centralize e automatize os processos de sua empresa com o ERP CODEZZ. Controle financeiro, gestão de estoque, vendas, compras, faturamento e serviços em uma única plataforma. Facilite a tomada de decisões com relatórios completos e eficientes, aumentando a produtividade e a eficiência de sua equipe. Simplifique a gestão do seu negócio e tenha todas as informações importantes ao seu alcance." />
        <RecursosAplicacao nameId="recursos-erp" :recursos="recursos" heightImg="225px" widthCard="430px" />

        <!-- Layout intuitivo -->
        <v-container>
            <h2 class="text-center ma-5">Layout Intuitivo</h2>
            <p class="text-center mb-4" style="font-weight: 300">O ERP Codezz possui um layout intuitivo focado na experiência do usuário, facilitando os processos operacionais do dia a dia.</p>

            <v-carousel cycle hide-delimiter-background show-arrows-on-hover height="30vh" >
                <v-carousel-item v-for="(item,i) in items" :key="i" height="100%" width="100%">
                    <v-img :src="item.src" contain height="100%" width="100%" loading="lazy" />
                </v-carousel-item>
            </v-carousel>
        </v-container>

        <v-divider class="mt-5"></v-divider>

        <!-- Integração -->
        <v-container>
            <h2 class="text-center ma-5">Integre o ERP com outros serviços</h2>
            <p class="text-center mb-0" style="font-weight: 300">Entre em contato conosco informando a plataforma que você deseja integrar com nosso ERP.</p>
            <p class="text-center" style="font-weight: 300">Também é possível fazer a integração com outros serviços da Codezz: <a href @click.prevent="navigateTo('/rastreamentos')"><strong>Rastreamentos</strong></a> e <a href @click.prevent="navigateTo('/agendamentos')"><strong>Agendamentos</strong></a>.</p>
        </v-container>
        
        <v-divider class="mt-5"></v-divider>

        <ContatoCodezz nameId="contato-erp" title="Entre em contato conosco" subtitle="Solicite um orçamento, peça uma demonstração ou esclareça suas dúvidas. Estamos aqui para ajudar você!" tipoSistema="erpCodezz" />
        <ClientesCodezz title="Empresas que utilizam a plataforma" tipoCliente="erpCodezz" />
    </div>
</template>

<script>
import ImageText from '@/components/templates/ImageText'
import RecursosAplicacao from '@/components/templates/RecursosAplicacao'
import ContatoCodezz from '@/components/templates/ContatoCodezz'
import ClientesCodezz from '@/components/templates/ClientesCodezz'
import { corAzul } from '@/config/global'

export default {
    name: 'ErpPage',
    components: {
        ImageText,
        RecursosAplicacao,
        ContatoCodezz,
        ClientesCodezz
    },
    data() {
        return {
            corAzul,
            recursos: [
                {
                    title: 'MÓDULO CADASTROS',
                    description: 'Organize seus dados com precisão: cadastre produtos em grade, gerencie fichas técnicas e mantenha todas as demais informações atualizadas e acessíveis.',
                    image: require('@/assets/erp/cadastros.jpg')
                },
                {
                    title: 'MÓDULO COMPRAS',
                    description: 'Dê entradas em notas fiscais de forma automatizada, abastecendo o seu estoque e gerando financeiro de forma inteligente.',
                    image: require('@/assets/erp/compras.png')
                },
                {
                    title: 'MÓDULO SERVIÇOS',
                    description: 'Controle eficiente de serviços: cadastre contratos, ajuste valores em massa e fature vários contratos ao mesmo tempo.',
                    image: require('@/assets/erp/servicos.png')
                },
                {
                    title: 'MÓDULO ESTOQUE',
                    description: 'Controle o seu estoque em tempo real, acompanhe movimentações e tenha uma visão detalhada de todas as operações relacionadas aos seus produtos.',
                    image: require('@/assets/erp/estoque.png')
                },
                {
                    title: 'MÓDULO FATURAMENTO',
                    description: 'Emita NF-e, NFS-e, NFC-e, controle o faturamento e acompanhe a performance de suas vendas de forma eficiente e segura.',
                    image: require('@/assets/erp/faturamento.jpg')
                },
                {
                    title: 'MÓDULO FINANCEIRO',
                    description: 'Gerencie contas a pagar e a receber, controle o fluxo de caixa e tenha uma visão completa da saúde financeira da sua empresa.',
                    image: require('@/assets/erp/financeiro.webp')
                }
            ],
            items: [
                { src: require('@/assets/erp/loginERP.jpeg') },
                { src: require('@/assets/erp/contasReceber.jpeg') },
                { src: require('@/assets/erp/notasFiscais.png') }
            ]
        }
    }, 
    methods: {
        navigateTo(route) {
            this.$router.push(route)
        }
    }
}
</script>

<style>
    .txt-color {
        color: rgb(92, 103, 184)
    }
</style>