<template>
    <div class="home-page">
        <ImageText nameId="inicio-rastreamento" maxHeight="420px" title="RASTREAMENTOS CODEZZ" subtitle="Otimize a logística dos pedidos de sua marca" description="Com os Rastreamentos da Codezz você centraliza os pedidos de sua marca na plataforma e rastreia-os diretamente com a transportadora. Com os Rastreamentos, a logística interna de sua empresa é automatizada e não será mais necessário gastar tempo realizando rastreamentos manuais nos sites das transportadoras. Além disso, seus clientes saberão o status de transporte dos seus pedidos." />
        <RecursosAplicacao nameId="recursos-rastreamentos" :recursos="recursos" heightImg="225px" widthCard="430px" />

        <!-- Transportadoras sincronizadas -->
        <v-container>
            <h2 class="text-center ma-5">Quais são as transportadoras sincronizadas com a plataforma?</h2>
            <p class="text-center mb-0" style="font-weight: 300">Entre em contato conosco informando quais as transportadoras parceiras de sua empresa, e iremos dizer-lhe se possuímos ela sincronizada.</p>
            <p class="text-center" style="font-weight: 300">Caso a transportadora possua uma API para realizarmos a integração, é possível sincronizá-la facilmente.</p>
        </v-container>
        
        <v-divider class="mt-5"></v-divider>

        <ContatoCodezz nameId="contato-rastreamentos" title="Entre em contato conosco" subtitle="Solicite um orçamento, peça uma demonstração ou esclareça suas dúvidas. Estamos aqui para ajudar você!" tipoSistema="rastreamentosCodezz" />
        <ClientesCodezz title="Empresas que utilizam a plataforma" tipoCliente="rastreamentosCodezz" />
    </div>
</template>

<script>
import ImageText from '@/components/templates/ImageText'
import RecursosAplicacao from '@/components/templates/RecursosAplicacao'
import ContatoCodezz from '@/components/templates/ContatoCodezz'
import ClientesCodezz from '@/components/templates/ClientesCodezz'
import { corAzul } from '@/config/global'

export default {
    name: 'RastreamentosPage',
    components: {
        ImageText,
        RecursosAplicacao,
        ContatoCodezz,
        ClientesCodezz
    },
    data() {
        return {
            corAzul,
            recursos: [
                {
                    title: 'CRIE TRANSPORTADORAS',
                    description: 'Crie e configure as transportadoras utilizadas pela sua marca.',
                    image: require('@/assets/rastreamentos/transportadoras.png'),
                    clickable: true 
                },
                {
                    title: 'CRIE FÁBRICAS E LOJAS',
                    description: 'Crie as fábricas que produzem os produtos de sua marca e as lojas que recebem esses pedidos.',
                    image: require('@/assets/rastreamentos/lojas.png'),
                    clickable: true 
                },
                {
                    title: 'CRIE E IMPORTE PEDIDOS',
                    description: 'Cadastre os pedidos de forma manual ou automática pela importação de XMLs de notas fiscais.',
                    image: require('@/assets/rastreamentos/pedidos.png'),
                    clickable: true 
                },
                {
                    title: 'RASTREAMENTO AUTOMÁTICO',
                    description: 'Se a transportadora está homologada é possível rastrear o pedido com apenas um clique.',
                    image: require('@/assets/rastreamentos/rastreamentos.png'),
                    clickable: true 
                },
                {
                    title: 'SAIBA O QUE AINDA NÃO FOI ENTREGUE',
                    description: 'Consulte os pedidos que ainda estão em transporte, bem como todo o histórico já entregue.',
                    image: require('@/assets/rastreamentos/ped-abertos.png'),
                    clickable: true 
                }
            ]
        }
    }
}
</script>

<style>
    .txt-color {
        color: rgb(92, 103, 184)
    }
</style>