<template>
    <v-content>
        <v-container class="texto-privacidade">
            <h1 class="text-center">Política de Privacidade</h1>
            <br>
            
            <h3>O que é este aviso?</h3>
            <span>Este Aviso de Privacidade explica como as informações pessoais ou as informações de sua empresa serão utilizadas pela Codezz em relação aos serviços e produtos digitais disponibilizados e oferecidos por nós.</span>
            <br><br>
            
            <h3>Quando as minhas informações serão coletadas?</h3>
            <span>Suas informações serão coletadas pela Codezz apenas quando você (ou terceiros) informarem-nas pra gente, através de:</span>
            <li class="ml-4">Formulários de contato no nosso site;</li>
            <li class="ml-4">Formulários de contato nos nossos produtos digitais (plataformas);</li>
            <li class="ml-4">E-mail.</li>
            <br>

            <h3>Quais informações serão coletadas?</h3>
            <span>As informações coletadas dependem do serviço/produto/contato que o cliente está contratando.</span>
            <li class="ml-4">No caso de pessoa física, serão coletados nome, CPF, telefone e e-mail pessoal;</li>
            <li class="ml-4">No caso de pessoa jurídica, serão coletados razão social, CNPJ, telefone e e-mail de contato.</li>
            <br>

            <h3>Para que as minhas informações serão utilizadas?</h3>
            <span>Os dados enviados por você (ou por terceiros) serão utilizados para fins de prestação dos nossos serviços e eventualmente para fins de marketing.</span>
            <br>
            <span>Os dados coletados também serão utilizados para monitorar e compreendermos os indicadores de nossos serviços e possibilitar a melhoria dos mesmos, tais como:</span>
            <li class="ml-4">Estabelecer quantas vezes nossos serviços foram visitados;</li>
            <li class="ml-4">Registrar quais serviços foram visitados para que possamos aprimorar o conteúdo e o layout de nossas plataformas;</li>
            <li class="ml-4">Compreender a eficácia dos nossos comunicados via e-mail;</li>
            <li class="ml-4">Monitorar a saúde dos nossos serviços, detectar acessos não autorizados e atividades fraudulentas, prevenir e responder a incidentes de segurança e escalar os recursos informáticos, de forma apropriada.</li>
            <br>

            <h3>A Codezz compartilhará as minhas informações?</h3>
            <span>As suas informações serão compartilhadas em situações específicas, abaixo apresentadas e não serão objeto de qualquer comercialização.</span>
            <li class="ml-4">Com alguns de nossos prestadores de serviço (por exemplo, fornecedores que hospedam nossos serviços);</li>
            <li class="ml-4">Caso seja uma exigência legal ou regulatória;</li>
            <li class="ml-4">Quando verificada uma desconformidade com as Leis Aplicáveis, com agências reguladoras;</li>
            <li class="ml-4">Com responsáveis legais ou parceiros comerciais responsáveis pelo pagamento de nossos serviços;</li>
            <li class="ml-4">Com terceiros específicos, caso você opte pelo uso de possíveis serviços terceirizados (você será ativamente notificado pela Codezz antes da realização de tal compartilhamento);</li>
            <li class="ml-4">Em caso de participações societárias;</li>
            <li class="ml-4">Em outras situações, com o consentimento e aprovação do usuário.</li>
            <br>

            <h3>E como fica o envio de marketing?</h3>
            <span>Eventualmente poderemos utilizar os dados sob nossa guarda para envio de comunicações de marketing (por meio de ligação ou e-mail). Tal hipótese poderá ocorrer, quando:</span>
            <li class="ml-4">Você tenha nos fornecido um consentimento válido e informado para este objetivo;</li>
            <li class="ml-4">Por meio de nosso legítimo interesse, desde que você tenha essa expectativa.</li>
            <br>

            <h3>Por quanto tempo nós manteremos as suas informações?</h3>
            <span>Manteremos os seus dados pelo tempo que os envolvidos estiverem usando os recursos de nossos serviços. Além disso, poderemos manter as informações por mais tempo, quando permitido pela Legislação Aplicável ou para que a Codezz possa defender os seus interesses em processos administrativos, judiciais e arbitrais.</span>
            <br><br>

            <h3>Que direitos eu tenho?</h3>
            <span>Você possui direitos sobre suas próprias informações, incluindo:</span>
            <li class="ml-4">Acesso, por meio de solicitação, às suas informações armazenadas em nossos bancos de dados;</li>
            <li class="ml-4">Correção de dados incompletos, inexatos ou desatualizados;</li>
            <li class="ml-4">Anonimação, bloqueio ou eliminação de dados desnecessários, excessivos, ou se acreditar que a Codezz está utilizando-os em desconformidade com a lei;</li>
            <li class="ml-4">Portabilidade dos dados a outro fornecedor de serviço ou produto;</li>
            <li class="ml-4">Informações sobre as entidades públicas ou privadas com as quais compartilhamos os seus dados;</li>
            <li class="ml-4">Obter revisão de eventuais decisões tomadas de forma automática;</li>
            <li class="ml-4">Revogação de qualquer consentimento feito.</li>
            <br>

            <h3>Como a Codezz mantém as informações seguras?</h3>
            <span>Na Codezz, mantemos medidas técnicas e organizacionais adequadas para evitar qualquer acesso não autorizado, situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado de dados.</span>
            <br><br>

            <h3>A Codezz enviará minhas informações a outro país?</h3>
            <span>Para alguns serviços, poderemos enviar/armazenar suas informações para outro país. Nesse caso, adotaremos todas as mesmas medidas para garantir que suas informações recebam nível de proteção descrito nesse Aviso de Privacidade.</span>
            <br><br>

            <h3>Como posso entrar em contato com a Codezz?</h3>
            <span>Caso você tenha dúvidas sobre esse Aviso de Privacidade, não hesite em contatar-nos através do e-mail:</span>
            <br>
            <li class="ml-4"><strong>codezz@codezz.com.br</strong></li>
            <br>

            <h3>Qual o significado dos termos utilizados nesse Aviso de Privacidade</h3>
            <li class="ml-4">Você: titular dos dados que utiliza os serviços da Codezz;</li>
            <li class="ml-4">Legislação Aplicável: Lei 13.709/2018 (Lei Geral de Proteção de Dados);</li>
            <li class="ml-4">Dados/Informações: Informações relacionada à pessoa identificada ou identificável, ou à empresa identificada ou identificável;</li>
            <li class="ml-4">Consentimento: Manifestação livre, informada ou equivocada pela qual o titular concorda com o tratamento de seus dados para uma finalidade determinada.</li>
            <br>

            <h3>Alterações a este Aviso de Privacidade</h3>
            <span>Poderemos atualizar este Aviso de Privacidade de tempos em tempos. Sempre incluiremos a data de uma nova versão para que você saiba quando a última atualização foi feita, bem como enviaremos avisos sobre novas atualizações.</span>
            <br><br>

            <h3>Última atualização</h3>
            <span>07/02/2022</span>
            <br><br>
        </v-container>
    </v-content>
</template>

<script>
export default {
    name: 'PrivacidadePage'
}
</script>

<style>
    .texto-privacidade {
        font-weight: 300;
    }
</style>