<template>
    <div :id="nameId">
        <v-img src="@/assets/bem_vindo.jpg" :max-height="maxHeight" loading="lazy">
            
            <v-container class="d-none d-lg-flex flex-column" :style="fonteBranca">
                <br>
                <h1 class="titulo mx-10">{{ title }}</h1>
                <h5 class="subtitulo mx-10">{{ subtitle }}</h5>
                <br>
                <v-col cols="6">
                    <h5 class="descricao mx-8">{{ description }}</h5>
                </v-col>
            </v-container>
            <v-container class="d-lg-none" fill-height fluid :style="fonteBranca">
                <v-row align="center" justify="center">
                    <v-col>
                        <h1 class="titulo mx-16">{{ title }}</h1>
                        <h5 class="subtitulo mx-16">{{ subtitle }}</h5>
                    </v-col>
                </v-row>
            </v-container>
        </v-img>

        <v-container class="d-lg-none" fill-height fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" class="text-center">
                    <br>
                    <h5 class="descricao mx-10">{{ description }}</h5>
                </v-col>
            </v-row>
        </v-container>

        <v-divider class="d-lg-none mt-5"></v-divider>
    </div>
</template>

<script>
import { fonteBranca } from '@/config/global'

export default {
    name: 'ImageText',
    props: ['nameId', 'maxHeight', 'title', 'subtitle', 'description'],
    data() {
        return {
            fonteBranca
        }
    }
}
</script>

<style>
    .titulo {
        font-size: 35px;
        font-weight: 700;
    }

    .subtitulo {
        font-size: 20px;
        font-weight: 500;
    }

    .descricao {
        font-size: 17px;
        font-weight: 300;
    }
</style>