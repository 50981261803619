<template>
    <div class="home-page">
        <ImageText nameId="inicio-fabrica_mei" maxHeight="420px" title="FÁBRICA DO MEI" subtitle="Tudo que o MEI precisa em um só lugar" description="A Fábrica do MEI centraliza todas as necessidades do Microempreendedor Individual em uma plataforma única. Gerencie guias mensais, controle de faturamento, emissão de notas fiscais, controle financeiro, cadastro de serviços e CNAEs pré-cadastrados, além de contratos e alertas de bloqueio por limite de faturamento. Tudo isso em uma interface intuitiva e prática, desenvolvida em parceria com a contabilidade Alfa Prime."/>
        <RecursosAplicacao nameId="recursos-fabrica_mei" :recursos="recursos" heightImg="225px" widthCard="430px" />

        <v-container>
            <h2 class="text-center ma-5">Saiba mais</h2>
            <p class="text-center mb-0" style="font-weight: 300">Para visualizar mais informações sobre a Fábrica do MEI, <a href class="redirecionamento" @click="abrirFabricaMei" ><strong>CLIQUE AQUI</strong></a></p>
        </v-container>
        
        <v-divider class="mt-5"></v-divider>
        <ContatoCodezz nameId="contato-rastreamentos" title="Entre em contato conosco" subtitle="Solicite um orçamento, peça uma demonstração ou esclareça suas dúvidas. Estamos aqui para ajudar você!" tipoSistema="rastreamentosCodezz" />

    </div>
</template>

<script>
import ImageText from '@/components/templates/ImageText'
import { corAzul } from '@/config/global'
import RecursosAplicacao from '../templates/RecursosAplicacao.vue'
import ContatoCodezz from '../templates/ContatoCodezz.vue'

export default {
    name: 'FabricaMeiPage',
    components: {
        ImageText,
        RecursosAplicacao,
        ContatoCodezz
    },
    data() {
        return {
            corAzul,
            recursos: [
                {
                    title: 'CADASTRE SERVIÇOS',
                    description: 'Cadastre serviços de forma prática sem se preocupar com o CNAE da sua empresa.',
                    image: require('@/assets/fabricaMei/servicos.png'),
                    clickable: true 
                },
                {
                    title: 'CONTROLE SEUS CONTRATOS',
                    description: 'Cadastre contratos, ajuste valores em massa e fature vários contratos ao mesmo tempo.',
                    image: require('@/assets/fabricaMei/contratos.png'),
                    clickable: true 
                },
                {
                    title: 'GUIAS MENSAIS',
                    description: 'Baixe a guia pela plataforma e tenha o controle de quando a guia está prestes a vencer.',
                    image: require('@/assets/fabricaMei/guias.png'),
                    clickable: true 
                },
                {
                    title: 'FATURAMENTO',
                    description: 'Emita suas notas fiscais de serviço de forma automatizada e controle seu faturamento.',
                    image: require('@/assets/fabricaMei/faturamento.png'),
                    clickable: true 
                },
                {
                    title: 'FINANCEIRO',
                    description: 'Controle o contas a receber e a pagar.',
                    image: require('@/assets/fabricaMei/financeiro.png'),
                    clickable: true 
                }
            ]
        }
    },
    methods: {
        abrirFabricaMei() {
            window.open(`https://fabricadomei.primealfa.com.br`, '_blank').focus()
        }
    }
}
</script>

<style>
    .txt-color {
        color: rgb(92, 103, 184)
    }
</style>