<template>
    <v-app-bar class="teste-site" id='inicio' elevate-on-scroll>
        <v-container class="teste-site">
            <v-toolbar flat class="teste-site">
                <img class="mr-3 ml-0" :src="require('@/assets/logo_codezz.png')" height="48px"/>
                <router-link style="text-decoration: none; color: black;" to="/">
                    <v-toolbar-title class="d-none d-sm-flex" style="font-weight: 700">CODEZZ SISTEMAS</v-toolbar-title>
                </router-link>
  
                <v-spacer></v-spacer>
  
                <v-toolbar-items v-for="(menu, i) in getMenus()" :key="i">
                    <v-btn text plain :to="menu.to" href="#" @click.prevent="goToElement(menu.toId)" :class="menu.showBig ? '' : 'hidden-sm-and-down'">
                        {{ menu.titulo }}
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
        </v-container>
    </v-app-bar>
</template>
  
<script>
export default {
    name: 'HeaderSite',
    methods: {
        getMenus() {
            if (this.$route.name == 'homePage') {
                return [
                    {
                        titulo: 'INÍCIO',
                        to: '/',
                        toId: 'inicio-home',
                        showBig: true
                    },
                    {
                        titulo: 'SOLUÇÕES',
                        to: '/',
                        toId: 'solucoes-home',
                        showBig: false
                    },
                    {
                        titulo: 'CONTATO',
                        to: '/',
                        toId: 'contato-home',
                        showBig: true
                    }
                ]
            } else {
                return [
                    {
                        titulo: 'INÍCIO',
                        to: '/',
                        name: 'inicio-home',
                        showBig: true
                    }
                ]
            }
        },
        goToElement(s) {
            document.getElementById(s).scrollIntoView()
        }
    }
}
</script>
  
<style>
</style>