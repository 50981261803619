import Vue from 'vue'

export const corAzul = 'rgb(92, 103, 184)'
export const corVerde = 'rgb(93, 254, 190)'
export const fonteBranca = 'color: white'
export const fundoAzul = 'background-color: ' + corAzul

const producaoCodezz = false

export const urlSiteCodezz = producaoCodezz ? 'https://www.codezz.com.br' : 'http://localhost:8080'
export const urlAtendimentos = producaoCodezz ? 'https://atendimentos.codezz.com.br' : 'http://localhost:8081'
export const urlAgendamentos = producaoCodezz ? 'https://agendamentos.codezz.com.br' : 'http://localhost:8081'
export const urlBackendCodezz = producaoCodezz ? 'https://api.codezz.com.br:3001' : 'http://localhost:3001'

export function showError(e) {
    if (e && e.response && e.response.data) {
        if (e.response.data.sqlMessage) {
            Vue.toasted.global.msgError({ msg: e.response.data.sqlMessage})
        } else {
            Vue.toasted.global.msgError({ msg: e.response.data })
        }
    } else if (typeof e === 'string') {
        Vue.toasted.global.msgError({ msg: e })
    } else {
        Vue.toasted.global.msgError()
    }
}

export default {
    corAzul,
    corVerde,
    fonteBranca,
    fundoAzul,
    urlSiteCodezz,
    urlAtendimentos,
    urlAgendamentos,
    showError
}
