import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePage from '@/components/pages/HomePage'
import ContabilPage from '@/components/pages/ContabilPage'
import AtendimentosPage from '@/components/pages/AtendimentosPage'
import AgendamentosPage from '@/components/pages/AgendamentosPage'
import RastreamentosPage from '@/components/pages/RastreamentosPage'
import ErpPage from '@/components/pages/ErpPage'
import FabricaMeiPage from '@/components/pages/FabricaMeiPage'
import PrivacidadePage from '@/components/pages/PrivacidadePage'
import ThanksPage from '@/components/pages/ThanksPage'

Vue.use(VueRouter)

const routes = [
    {
        name: 'homePage',
        path: '/',
        component: HomePage
    },
    {
        name: 'integradorContabil',
        path: '/integrador-contabil',
        component: ContabilPage
    },
    {
        name: 'gerenciadorAtendimentos',
        path: '/gerenciador-atendimentos',
        component: AtendimentosPage
    },
    {
        name: 'agendamentosCodezz',
        path: '/agendamentos',
        component: AgendamentosPage
    },
    {
        name: 'rastreamentosCodezz',
        path: '/rastreamentos',
        component: RastreamentosPage
    },
    {
        name: 'erpCodezz',
        path: '/erp',
        component: ErpPage
    },
    {
        name: 'fabricaMei',
        path: '/fabrica_mei',
        component: FabricaMeiPage
    },
    {
        name: 'obrigado',
        path: '/obrigado',
        component: ThanksPage
    },
    {
        name: 'politicaPrivacidade',
        path: '/politica-privacidade',
        component: PrivacidadePage
    }
]

// Função disparada pelo vue-router quando uma rota muda. Assim sempre que uma nova página é acessada ele inicia no começo da página (x=0 e y=0). O default é não mudar o x e y...
const scrollBehavior = () => {
    return {
        x: 0,
        y: 0
    }
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior
})

export default router
