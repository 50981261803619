export const clientes = [
    {
        nome: 'Alfa Prime Assessoria Empresarial',
        imagem: require('@/assets/clientes/alfaprime.png'),
        site: 'https://primealfa.com.br/',
        tipo: [
            'clienteConfianca',
            'gerenciadorAtendimentos',
            'integradorContabil'
        ]
    },
    {
        nome: 'Codezz Soluções em Tecnologia',
        imagem: require('@/assets/clientes/codezz.png'),
        site: 'https://www.codezz.com.br',
        tipo: [
            'gerenciadorAtendimentos'
        ]
    },
    {
        nome: 'ERP Infobox',
        imagem: require('@/assets/clientes/infobox.jpeg'),
        site: 'https://erpinfobox.com.br/',
        tipo: [
            'clienteConfianca'
        ]
    },
    {
        nome: 'Sapatinho de Luxo',
        imagem: require('@/assets/clientes/sapatinho-luxo.png'),
        site: 'https://www.sapatinhodeluxo.com.br',
        tipo: [
            'clienteConfianca',
            'rastreamentosCodezz'
        ]
    },
    {
        nome: 'Barbearia Bohemios',
        imagem: require('@/assets/clientes/bohemios.jpg'),
        site: 'https://www.instagram.com/barbeariabohemios/',
        tipo: [
            'clienteConfianca',
            'agendamentosCodezz'
        ]
    },
    {
        nome: 'Fábrica do MEI',
        imagem: require('@/assets/clientes/fabricaDoMei.png'),
        site: 'https://fabricadomei.com.br/',
        tipo: [
            'clienteConfianca',
            'erpCodezz'
        ]
    },
    {
        nome: 'Ruby Design',
        imagem: require('@/assets/clientes/ruby.jpg'),
        site: '',
        tipo: [
            'clienteConfianca',
            'erpCodezz'
        ]
    },
    {
        nome: 'Grinsul - Soluções Inteligentes',
        imagem: require('@/assets/clientes/grinsul.jpg'),
        site: 'https://www.grinsul.com.br/',
        tipo: [
            'clienteConfianca',
            'erpCodezz'
        ]
    }
    
]

export default {
    clientes
}