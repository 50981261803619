<template>
    <div class="home-page">
        <ImageText nameId="inicio-home" maxHeight="360px" title="SEJA BEM VINDO" subtitle="Transformamos a sua ideia em produtos digitais" description="Somos uma empresa de desenvolvimento de software que atende pequenas e médias empresas. Oferecemos ao cliente um produto digital feito sob medida de acordo com as suas próprias necessidades. Além disso, também possuímos soluções para atender a sua empresa, oferecendo a possibilidade de customização do produto conforme demanda." />

        <!-- O que ofereçemos -->
        <v-container>
            <h2 class="text-center ma-5">O que oferecemos</h2>
            <v-row>
                <v-col v-for="(offer, i) in offers" :key="i" align-self="center" class="text-center d-flex justify-center">
                    <v-hover v-slot="{ hover }">
                        <v-card :elevation="hover ? 14 : 4" width="350px" height="300px" class="d-flex flex-column align-center">
                            <h4 class="pt-2">{{ offer.titulo }}</h4>
                            <v-icon x-large class="pa-4">{{ offer.icone }}</v-icon>
                            <p class="px-2">{{ offer.descricao }}</p>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-container>
        <v-divider class="mt-5"></v-divider>

        <!-- Soluções -->
        <v-container id="solucoes-home">
            <h2 class="text-center ma-5">Soluções disponíveis para você</h2>
            <v-row>
                <v-col v-for="(solution, i) in solutions" :key="i" align-self="center" class="d-flex justify-center">
                    <v-hover v-slot="{ hover }">
                        <v-card :elevation="hover ? 14 : 4" class="mx-auto" max-width="375px">
                            <v-img :src="solution.imagem" height="200px" loading="lazy"></v-img>
                            <h3 class="pa-4">{{ solution.titulo }}</h3>
                            <p class="px-4">{{ solution.subtitulo }}</p>
                            <v-card-actions>
                                <v-btn rounded dark text :to="solution.toRoute">
                                    <h4>{{ solution.toRoute != '' ? 'SAIBA MAIS' : 'EM BREVE...'}}</h4>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn icon color="white" @click="solution.show = !solution.show">
                                    <v-icon>{{ solution.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-card-actions>
                            <v-expand-transition>
                                <div v-show="solution.show">
                                    <v-divider></v-divider>
                                    <p class="pt-4 px-4">{{ solution.descricao }}</p>
                                </div>
                            </v-expand-transition>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-container>
        <v-divider class="mt-5"></v-divider>

        <ClientesCodezz title="Empresas que confiam na Codezz" tipoCliente="clienteConfianca" />
        <ContatoCodezz nameId="contato-home" title="Entre em contato conosco" subtitle="Esclareça todas as suas dúvidas com a gente, sem compromisso!" tipoSistema="" />
    </div>
</template>

<script>
import ImageText from '@/components/templates/ImageText'
import ContatoCodezz from '@/components/templates/ContatoCodezz'
import ClientesCodezz from '@/components/templates/ClientesCodezz'
import { corAzul } from '@/config/global'

export default {
    name: 'HomePage',
    components: {
        ImageText,
        ContatoCodezz,
        ClientesCodezz
    },
    data() {
        return {
            corAzul,
            offers: [
                {
                    titulo: 'DESENVOLVIMENTO WEB',
                    icone: 'mdi-web',
                    descricao: 'Sua ideia acessada diretamente no navegador de sua preferência, através do seu computador, tablet ou smartphone. A grande vantagem da web é a facilidade de acesso ao sistema a partir de qualquer tipo de dispositivo e em qualquer local do mundo que possua conexão com a internet.'
                },
                {
                    titulo: 'DESENVOLVIMENTO MOBILE',
                    icone: 'mdi-cellphone',
                    descricao: 'Seu produto acessado por um aplicativo no seu smartphone ou tablet, seja ele Android ou iOS. O diferencial do produto mobile é a praticidade de acesso diretamente da palma de sua mão e em qualquer momento. Outro diferencial é que não é necessário ter uma conexão à internet para usá-lo.'
                },
                {
                    titulo: 'DESENVOLVIMENTO DESKTOP',
                    icone: 'mdi-desktop-mac',
                    descricao: 'Desenvolvemos o seu software para ser acessado no computador, seja ele Windows, Linux ou MacOS. A vantagem dos sistemas desktop é o maior poder de processamento, maior segurança dos dados, facilidade na manipulação de arquivos e a possibilidade de integração com outros sistemas.'
                },
                {
                    titulo: 'DESENVOLVIMENTO DE SITES',
                    icone: 'mdi-application-outline',
                    descricao: 'Desenvolvemos o site de apresentação da sua empresa, deixando-o exatamente da forma que você deseja e com as informações que a empresa precisa apresentar aos seus clientes. Caso você não tenha um layout definido, indicamos empresas parceiras para o desenvonvolvimento do mesmo.'
                }
            ],
            solutions: [
                {
                    titulo: 'ERP CODEZZ',
                    subtitulo: 'Gerencie todas as operações da sua empresa de forma integrada e eficiente',
                    descricao: 'Centralize e automatize os processos de sua empresa com o ERP CODEZZ. Controle financeiro, gestão de estoque, vendas e compras em uma única plataforma. Facilite a tomada de decisões com relatórios completos e eficientes, aumentando a produtividade e a eficiência de sua equipe. Simplifique a gestão do seu negócio e tenha todas as informações importantes ao seu alcance.',
                    imagem: require('@/assets/solutions/erp1.webp'),
                    toRoute: '/erp',
                    show: false
                },
                {
                    titulo: 'Fábrica do MEI',
                    subtitulo: 'Gerencia todas as operações do seu MEI em um só lugar',
                    descricao: 'Centralize todas as necessidades da sua empresa MEI, automatize os pagamentos das guias mensais, emita suas notas fiscais com apenas um clique, controle seu faturamento e suas contas a receber e pagar. Tudo isso em um só lugar, com uma interface intuitiva e de fácil usabilidade, além de contar com uma acessoria contábil especializada.',
                    imagem: require('@/assets/solutions/erp.webp'),
                    toRoute: '/fabrica_mei',
                    show: false
                },
                {
                    titulo: 'RASTREAMENTOS CODEZZ',
                    subtitulo: 'Rastreie automaticamente os pedidos de sua marca, independentemente da transportadora',
                    descricao: 'Centralize os pedidos de sua marca na plataforma e rastreie automaticamente todos os pedidos diretamente com a transportadora. Automatize sua logística interna sem se preocupar em fazer rastreamentos manuais. Configure toda a plataforma e deixe seus clientes saberem onde estão seus pedidos.',
                    imagem: require('@/assets/solutions/rastreamentos.jpg'),
                    toRoute: '/rastreamentos',
                    show: false
                },
                {
                    titulo: 'AGENDAMENTOS CODEZZ',
                    subtitulo: 'Deixe seus clientes marcarem seus próprios agendamentos pelo site da plataforma',
                    descricao: 'Centralize os agendamentos de seus clientes em um único lugar, substituindo a necessidade de uma agenda. Cadastre seus serviços, preços, horários de trabalho, gere agendas por período e por cada funcionário da empresa e deixe seus clientes marcarem seus próprios agendamentos através do site.',
                    imagem: require('@/assets/solutions/agendamentos.jpg'),
                    toRoute: '/agendamentos',
                    show: false
                },
                {
                    titulo: 'GERENCIADOR DE ATENDIMENTOS',
                    subtitulo: 'Organize os atendimentos de sua empresa e melhore seu relacionamento com o cliente',
                    descricao: 'Centralize os atendimentos de seus clientes em um único lugar, distribua cada atendimento ao setor e funcionário específico, saiba tudo o que está pendente de realização e tudo o que já foi concluído. Tudo isso em uma plataforma web que pode ser acessada em qualquer local e em todo momento.',
                    imagem: require('@/assets/solutions/atendimentos.jpg'),
                    toRoute: '/gerenciador-atendimentos',
                    show: false
                },
                {
                    titulo: 'INTEGRADOR CONTÁBIL',
                    subtitulo: 'Automatize todos os processos internos de sua contabilidade',
                    descricao: 'O Integrador Contábil funciona como ferramenta de integração, automação e otimização das tarefas realizadas pelos contadores, sendo desenvolvido para trabalhar em conjunto com o sistema contábil da empresa. Com ele você se vê livre de realizar cadastros, conferências e conversões de forma manual.',
                    imagem: require('@/assets/solutions/contabil.jpg'),
                    toRoute: '/integrador-contabil',
                    show: false
                }
            ]
        }
    }
}
</script>

<style >
.home-page {
    font-family: 'Roboto', sans-serif;
    color: #333;
    background: linear-gradient(135deg, #E3F2FD 0%, #FFFFFF 100%);
}

.home-page h2 {
    color: #1E88E5;
}

.home-page .v-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.v-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.home-page .v-btn {
    background-color: #1E88E5;
    color: #fff;
    transition: background-color 0.3s ease;
}

.v-btn:hover {
    background-color: #1565C0;
}

.v-icon {
    color: #1E88E5;
}

.home-page .v-divider {
    background-color: #1E88E5;
}

.text-center {
    text-align: center;
}

.ma-5 {
    margin: 40px 0;
}

.pa-4 {
    padding: 16px;
}

.pt-2 {
    padding-top: 8px;
}

.px-2 {
    padding: 0 8px;
}

.px-4 {
    padding: 0 16px;
}

.pt-4 {
    padding-top: 16px;
}

.teste-site {
    background: linear-gradient(135deg, #E3F2FD 0%, #FFFFFF 100%);
    font-family: 'Roboto', sans-serif;
    color: #333;
}

.teste-site .v-btn {
    color: black;
    /* Estilos para botões do header */
}

.teste-site .v-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.agendamento-page .v-btn {
    background-color: #1E88E5;
    color: #fff; /* Define o texto como branco */
    transition: background-color 0.3s ease;
}

.agendamento-page .v-btn:hover {
    background-color: #1565C0;
    color: #fff; /* Mantém o texto branco ao passar o mouse */
}


</style>
